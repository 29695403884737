import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import moment from 'moment';
import { supabase } from '../supabaseClient';

const EditProjectGroupModal = ({ show, onHide, group, onUpdate }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    is_favorite: false,
    parent_project_group_id: null,
    is_archived: false
  });
  const [parentGroups, setParentGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingParents, setLoadingParents] = useState(false);

  useEffect(() => {
    if (show && group) {
      setFormData({
        name: group.name || '',
        description: group.description || '',
        is_favorite: group.is_favorite || false,
        parent_project_group_id: group.parent_project_group_id || null,
        is_archived: group.is_archived || false
      });
      fetchParentGroups(group.id);
    }
  }, [show, group]);

  const fetchParentGroups = async (currentId) => {
    if (!currentId) return;
    setLoadingParents(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name')
        .neq('id', currentId)
        .order('name');
      if (error) throw error;
      setParentGroups(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingParents(false);
    }
  };

  const handleClose = () => {
    setError(null);
    setFormData({
      name: '',
      description: '',
      is_favorite: false,
      parent_project_group_id: null,
      is_archived: false
    });
    onHide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('project_groups')
        .update(formData)
        .eq('id', group.id)
        .select(`
          *,
          projects (
            id,
            name,
            description
          )
        `);
      if (error) throw error;
      onUpdate(data[0]);
      handleClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Parent Project Group</Form.Label>
            <Form.Select
              value={formData.parent_project_group_id || ''}
              onChange={(e) => {
                const value = e.target.value;
                setFormData({
                  ...formData,
                  parent_project_group_id: value === '' ? null : value
                });
              }}
            >
              <option value="">None</option>
              {parentGroups.map(pg => (
                <option 
                  key={pg.id} 
                  value={pg.id}
                  disabled={pg.id === group.id}
                >
                  {pg.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Favorite"
              checked={formData.is_favorite}
              onChange={(e) => setFormData({...formData, is_favorite: e.target.checked})}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Archived"
              checked={formData.is_archived}
              onChange={(e) => setFormData({...formData, is_archived: e.target.checked})}
            />
          </Form.Group>

          {group && (
            <div className="text-muted" style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
              <div>Created {moment(group.created_at).fromNow()}</div>
              {group.updated_at && (
                <div>Last modified {moment(group.updated_at).fromNow()}</div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditProjectGroupModal;