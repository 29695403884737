import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Card, Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaEdit, FaTrash, FaPlus, FaBookmark, FaRegBookmark, FaArchive, FaRegFile, FaFolder, FaLayerGroup } from 'react-icons/fa';
import { FaChartGantt } from 'react-icons/fa6';
import { useToast } from '../ToastContext';
import AddProjectGroupModal from '../components/AddProjectGroupModal';
import EditProjectGroupModal from '../components/EditProjectGroupModal';
import DeleteProjectGroupModal from '../components/DeleteProjectGroupModal';
import AddProjectModal from '../components/AddProjectModal';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';

function ProjectGroups() {
  const [projectGroups, setProjectGroups] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { user } = useContext(AuthContext);
  const addToast = useToast();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectGroups = async () => {
      try {
        // Only fetch if we have a user
        if (!user) return;
        
        const { data, error } = await supabase
          .from('project_groups')
          .select(`
            *,
            projects(count),
            child_groups:project_groups!parent_project_group_id(count)
          `)
          .eq('user_id', user.id)
          .order('is_favorite', { ascending: false })
          .order('name', { ascending: true });
        if (error) throw error;
        console.log('Fetched data:', data);
        setProjectGroups(data || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProjectGroups();
  }, [user]); // Keep user in dependencies

  // Update loading state check to also check for user
  if (!user || loading) return <p>Loading...</p>;
  if (error) return <p>Failed: {error}</p>;
  if (!Array.isArray(projectGroups)) return <p>No project groups found.</p>;

  const handleAddProjectGroup = () => {
    setShowAddModal(true);
  };

  const handleEditProjectGroup = (group) => {
    setSelectedGroup(group);
    setShowEditModal(true);
  };

  const handleDeleteProjectGroup = (group) => {
    setSelectedGroup(group);
    setShowDeleteModal(true);
  };

  const handleAddProject = (groupId) => {
    setSelectedGroupId(groupId);
    setShowAddProjectModal(true);
  };

  const toggleFavorite = async (groupId, isFavorite) => {
    const { error } = await supabase
      .from('project_groups')
      .update({ is_favorite: !isFavorite })
      .eq('id', groupId)
      .single();

    if (error) {
      console.error('Error updating favorite status:', error);
      addToast('Error updating favorite status: ' + error.message, 'danger');
    } else {
      setProjectGroups((prev) =>
        prev
          .map((group) => (group.id === groupId ? { ...group, is_favorite: !isFavorite } : group))
          .sort((a, b) => {
            if (a.is_favorite === b.is_favorite) {
              return a.name.localeCompare(b.name);
            }
            return b.is_favorite - a.is_favorite;
          })
      );
      addToast('Favorite status updated successfully', 'success');
    }
  };

  const toggleArchive = async (groupId, isArchived) => {
    const { error } = await supabase
      .from('project_groups')
      .update({ is_archived: !isArchived })
      .eq('id', groupId)
      .single();

    if (error) {
      console.error('Error updating archive status:', error);
      addToast('Error updating archive status: ' + error.message, 'danger');
    } else {
      setProjectGroups((prev) =>
        prev.map((group) => 
          group.id === groupId ? { ...group, is_archived: !isArchived } : group
        )
      );
      addToast('Archive status updated successfully', 'success');
    }
  };

  return (
    <Container className="mt-5">
      <h1>
        <FaLayerGroup className="me-2" />
        Project Groups
      </h1>

      <Row className="mt-3">
        {projectGroups.map((group) => (
          <Col key={group.id} sm={12} md={6} lg={4} className="mb-3">
            <Card className="project-group-card">
              <Card.Body>
                <Card.Title className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <Link 
                      to={`/project-group/${group.id}`} 
                      className={`text-decoration-none ${group.is_archived ? 'text-black-50 opacity-50' : ''}`}
                    >
                      <FaFolder className="me-2" />
                      {group.name}
                    </Link>
                  </div>
                  <div className="d-flex">
                    <Badge pill bg="secondary" className="me-5">
                      <FaFolder />&nbsp;
                      {group.child_groups?.[0]?.count || 0}
                    </Badge>
                    <Badge pill bg="info">
                      <FaChartGantt />&nbsp;
                      {group.projects?.[0]?.count || 0}
                    </Badge>
                  </div>
                </Card.Title>
                <Card.Text className={group.is_archived ? 'text-muted' : ''}>
                  {group.description}
                </Card.Text>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-addproj-${group.id}`}>
                        {group.is_archived ? 'Unarchive to add project' : 'Add Project'}
                      </Tooltip>}
                    >
                      <span>
                        <Button variant="link" onClick={() => handleAddProject(group.id)} disabled={group.is_archived}>
                          <FaPlus color={group.is_archived ? '#6c757d' : '#000000'}/>
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-edit-${group.id}`}>Edit Project Group</Tooltip>}
                    >
                      <span>
                        <Button variant="link" onClick={() => handleEditProjectGroup(group)} disabled={group.is_archived}>
                          <FaEdit color={group.is_archived ? '#6c757d' : '#000000'} />
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-delete-${group.id}`}>Delete Project Group</Tooltip>}
                    >
                      <Button variant="link" onClick={() => handleDeleteProjectGroup(group)}>
                        <FaTrash />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-favorite-${group.id}`}>
                        {group.is_favorite ? 'Remove from Favorites' : 'Add to Favorites'}
                      </Tooltip>}
                    >
                      <Button variant="link" onClick={() => toggleFavorite(group.id, group.is_favorite)}>
                        <FaBookmark color={group.is_favorite ? '#f4b941' : '#6c757d'} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-archive-${group.id}`}>
                        {group.is_archived ? 'Unarchive Group' : 'Archive Group'}
                      </Tooltip>}
                    >
                      <Button variant="link" onClick={() => toggleArchive(group.id, group.is_archived)}>
                        <FaArchive color={group.is_archived ? '#f4b941' : '#6c757d'} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Button variant="primary" onClick={handleAddProjectGroup}>
        <FaPlus /> Add Project Group
      </Button>

      {showAddModal && (
        <AddProjectGroupModal
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          userId={user.id}
          onProjectGroupAdded={(newGroup) => setProjectGroups([...projectGroups, newGroup])}
        />
      )}
      {showEditModal && (
        <EditProjectGroupModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          group={selectedGroup}
          onUpdate={(updatedGroup) => {
            setProjectGroups((prev) =>
              prev.map((group) => (group.id === updatedGroup.id ? updatedGroup : group))
            );
            setShowEditModal(false);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteProjectGroupModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          item={selectedGroup}
          onItemDeleted={(deletedGroupId) =>
            setProjectGroups((prev) => prev.filter((group) => group.id !== deletedGroupId))
          }
        />
      )}
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          projectGroupId={selectedGroupId}
          onProjectAdded={(newProject) =>
            setProjectGroups((prev) =>
              prev.map((group) =>
                group.id === selectedGroupId
                  ? { ...group, projects: [...group.projects, newProject] }
                  : group
              )
            )
          }
        />
      )}
    </Container>
  );
}

export default ProjectGroups;
