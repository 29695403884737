import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext';
import Cookies from 'js-cookie';

// Apply the saved theme before rendering the app
const applyTheme = (theme) => {
  document.body.classList.remove('dark-mode', 'light-mode', 'bg-dark', 'text-white', 'bg-light', 'text-dark');
  if (theme === 'dark') {
    document.body.classList.add('dark-mode', 'bg-dark', 'text-white');
  } else if (theme === 'light') {
    document.body.classList.add('light-mode', 'bg-light', 'text-dark');
  }
};

const savedTheme = Cookies.get('theme') || 'auto';
applyTheme(savedTheme);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();