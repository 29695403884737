import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function AddProjectGroupModal({ show, handleClose, userId, onProjectGroupAdded }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    is_favorite: false,
    parent_project_group_id: null,
    is_archived: false,
    user_id: userId  // Changed from owner to user_id to match database schema
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projectGroups, setProjectGroups] = useState([]);
  const addToast = useToast();

  useEffect(() => {
    const fetchProjectGroups = async () => {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name')
        .eq('user_id', userId)  // Changed from owner to user_id
        .eq('is_archived', false);
      
      if (error) {
        console.error('Error fetching groups:', error);
        return;
      }
      console.log('Fetched groups:', data);
      setProjectGroups(data);
    };

    if (show) {
      fetchProjectGroups();
    }
  }, [show, userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('project_groups')
        .insert([formData])
        .select(`
          *,
          projects (
            id,
            name,
            description
          )
        `);
      if (error) throw error;
      
      addToast('Project group added successfully', 'success');
      onProjectGroupAdded(data[0]);
      handleClose();
    } catch (err) {
      setError(err.message);
      addToast('Error adding project group: ' + err.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Project Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Parent Project Group</Form.Label>
            <Form.Select
              value={formData.parent_project_group_id || ''}
              onChange={(e) => {
                const value = e.target.value;
                console.log('Selected value:', value);
                setFormData({
                  ...formData,
                  parent_project_group_id: value === '' ? null : value
                });
              }}
            >
              <option value="">None</option>
              {projectGroups.map(pg => (
                <option key={pg.id} value={pg.id}>
                  {pg.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Favorite"
              checked={formData.is_favorite}
              onChange={(e) => setFormData({...formData, is_favorite: e.target.checked})}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddProjectGroupModal;