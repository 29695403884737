import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Row, Col, Card, Badge } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaPlus, FaStar, FaRegStar, FaBookmark, FaArchive, FaFolder, FaFile, FaLayerGroup } from 'react-icons/fa';
import { FaChartGantt } from 'react-icons/fa6';
import { useToast } from '../ToastContext';
import AddProjectModal from '../components/AddProjectModal';
import ProjectCard from '../components/ProjectCard';
import { AuthContext } from '../AuthContext';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

function ProjectGroupDetail() {
  const { id } = useParams();
  const [projectGroup, setProjectGroup] = useState(null);
  const [childGroups, setChildGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const { user } = useContext(AuthContext);
  const addToast = useToast();
  const [parentChain, setParentChain] = useState([]);

  const fetchParentChain = async (groupId, chain = []) => {
    if (!groupId) return chain;
    
    const { data, error } = await supabase
      .from('project_groups')
      .select('id, name, parent_project_group_id')
      .eq('id', groupId)
      .single();
      
    if (error || !data) return chain;
    
    chain.unshift(data);
    
    if (data.parent_project_group_id) {
      return fetchParentChain(data.parent_project_group_id, chain);
    }
    
    return chain;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        // First query - get main project group with its projects
        const { data: groupData, error: groupError } = await supabase
          .from('project_groups')
          .select(`
            *,
            projects!project_group_id (
              id,
              name,
              description,
              is_favorite,
              is_archived,
              created_at,
              updated_at,
              user_id,
              project_group:project_group_id (
                id,
                name
              ),
              tasks!project_id (
                id,
                name,
                description
              )
            ),
            parent_project_group:parent_project_group_id (
              id,
              name
            )
          `)
          .eq('id', id)
          .eq('user_id', user.id)
          .single();

        if (groupError) throw groupError;

        // Second query - get child project groups with counts
        const { data: childrenData, error: childrenError } = await supabase
          .from('project_groups')
          .select(`
            *,
            projects:projects(count),
            child_groups:project_groups!parent_project_group_id(count)
          `)
          .eq('parent_project_group_id', id)
          .eq('user_id', user.id);

        if (childrenError) throw childrenError;

        // Ensure projects array exists even if empty
        const processedGroupData = {
          ...groupData,
          projects: groupData?.projects || []
        };

        setProjectGroup(processedGroupData);
        setChildGroups(childrenData || []);

        // Fetch parent chain
        const parentChainData = await fetchParentChain(id);
        setParentChain(parentChainData);

      } catch (error) {
        console.error('Error:', error);
        addToast('Error loading project group: ' + error.message, 'danger');
      } finally {
        setLoading(false);
      }
    };

    if (id && user) {
      fetchData();
    }
  }, [id, user, addToast]);

  const handleProjectUpdated = (updatedProject) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: prev.projects.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      ),
    }));
  };

  const handleProjectDeleted = (deletedProjectId) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: prev.projects.filter((project) => project.id !== deletedProjectId),
    }));
  };

  const handleProjectAdded = (newProject) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: [...prev.projects, newProject],
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!projectGroup) {
    return <div>Project group not found</div>;
  }

  return (
    <Container className="mt-5">
      {/* Breadcrumb navigation */}
      <div className="mb-3">
        <Link to="/project-groups">
          <FaLayerGroup className="me-1" />
          Project Groups
        </Link>
        {parentChain.map((parent, index) => (
          <span key={parent.id}>
            {' > '}
            {index === parentChain.length - 1 ? (
              <>
                <FaFolder className="me-1" />
                {parent.name}
              </>
            ) : (
              <Link to={`/project-group/${parent.id}`}>
                <FaFolder className="me-1" />
                {parent.name}
              </Link>
            )}
          </span>
        ))}
      </div>

      {/* Project Group Details Card */}
      <Card className="mb-4">
        <Card.Body>
          <Card.Title className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0">
              {projectGroup.name}
              {projectGroup.is_favorite && <FaStar color="gold" className="ms-2" />}
              {projectGroup.is_archived && <FaArchive color="#6c757d" className="ms-2" />}
            </h1>
            {/* Update Badge display with null check */}
            <Badge pill bg="info">
              {projectGroup.projects?.length || 0} Projects
            </Badge>
          </Card.Title>
          <Card.Text>{projectGroup.description}</Card.Text>
          <div className="text-muted" style={{ fontSize: '0.8em' }}>
            <div>Created {moment(projectGroup.created_at).fromNow()}</div>
            {projectGroup.updated_at && (
              <div>Last modified {moment(projectGroup.updated_at).fromNow()}</div>
            )}
          </div>
        </Card.Body>
      </Card>

      {/* Child Project Groups */}
      {/* Update child groups section with null check */}
      {(childGroups?.length > 0) && (
        <>
          <h3>Nested Project Groups</h3>
          <Row className="mb-4">
            {childGroups.map((group) => (
              <Col key={group.id} sm={12} md={6} lg={4} className="mb-3">
                <Card>
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-between align-items-center">
                      <div>
                        <Link to={`/project-group/${group.id}`} className="text-decoration-none">
                          <FaFolder className="me-2" />
                          {group.name}
                        </Link>
                        {group.is_favorite && <FaBookmark color="#f4b941" className="ms-2" />}
                      </div>
                      <div>
                        <Badge pill bg="secondary" className="me-2">
                          <FaFolder className="me-1" />
                          {group.child_groups?.count || 0}
                        </Badge>
                        <Badge pill bg="info">
                          <FaChartGantt className="me-1" />
                          {group.projects?.count || 0}
                        </Badge>
                      </div>
                    </Card.Title>
                    <Card.Text>{group.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {/* Projects Section */}
      <h2>
        <FaChartGantt className="me-2" />
        Projects
      </h2>
      <Button variant="primary" onClick={() => setShowAddProjectModal(true)} className="mb-3">
        <FaPlus /> Add Project
      </Button>

      {/* Update projects section with null check */}
      <Row>
        {projectGroup.projects?.map((project) => (
          <Col key={project.id} sm={12} md={6} lg={4} className="mb-3">
            <ProjectCard
              project={project}
              onProjectUpdated={handleProjectUpdated}
              onProjectDeleted={handleProjectDeleted}
              onToggleFavorite={handleProjectUpdated}
            />
          </Col>
        ))}
      </Row>

      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          projectGroupId={projectGroup.id}
          onProjectAdded={handleProjectAdded}
        />
      )}
    </Container>
  );
}

export default ProjectGroupDetail;